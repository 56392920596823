.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}


.App-header {
  background-color: #B94053;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.button-group {
  display: flex;
  position: absolute;
  top: 10px;
  right: 10px;
}

.top-button {
  margin-right: 10px;
  font-size: 0.5em;
  color: aliceblue;
  text-decoration: none;
}